@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
/*  */

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar-track {
    background: #212529;
    /* color of the tracking area */
    border-radius: 20px;
    /* roundness of the tracking area */
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar-thumb {
    background-color: #33AA72;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid #212529;
    /* creates padding around scroll thumb */
}

.projects-section {
    margin: 0;
    padding: 50px 20px;
}

.projects-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.projects-title h1 {
    color: white;
    font-family: "Righteous";
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    text-transform: uppercase;
}

.projects-title span {
    color: #33AA72;
}

.filter {
    margin: 20px auto;
}

.btn-filter {
    border-radius: 0;
    border: 0;
    margin: 2px;
    background-color: #33AA72;
    font-family: "Red Hat Display";
    text-transform: uppercase;
    font-size: 15px;
}

.btn-filter:hover {
    background-color: #018f4d;
}

.slider {
    width: 100%;
    margin: 0 auto;
}

.modal-caroussel {
    margin: 0 auto;
    width: 90%;
}

.modal-img {
    width: 100%;
}

/* .react-horizontal-scrolling-menu--wrapper {
    flex-wrap: wrap;
    justify-content: center;
}

.react-horizontal-scrolling-menu--scroll-container {
    flex-basis: 100%;
    flex-shrink: 0;
    order: 1;
}
*/