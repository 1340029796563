@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
/*  */

.services-section {
    margin: 0;
    padding: 50px 0;
}

.services-content {
    padding: 50px 60px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.services-title h1 {
    color: white;
    font-family: "Righteous";
    font-size: 60px;
    line-height: 60px;
    text-align: center;
}

.services-title span {
    color: #33AA72;
}

.sized-box {
    height: 0;
    width: 300px;
}

.service {
    padding: 10px 20px 10px 10px;
}

.service h3 {
    color: white;
    font-family: "Righteous";
    font-size: 25px;
    text-align: left;
}

.service p {
    color: rgb(206, 205, 205);
    font-family: 'Red Hat Display', sans-serif;
    font-size: 18px;
    text-align: left;
}

/* Tablet view */

@media (max-width: 768px) {
    .services-content {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .sized-box {
        height: 30px;
        width: 0;
    }
}

/* Mobile view */

@media (max-width: 576px) {
    .service-img img{
        width: 320px;
    }
}