@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300&display=swap');
/*  */

.main-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 120px;
}

.gif {
    width: 50px;
    height: 50px;
}

.main-text {
    margin-right: auto;
}

.main-text>h3 {
    color: white;
    font-family: "Righteous";
    font-size: 48px;
    line-height: 60px;
}

.typer {
    font-family: "Teko", sans-serif;
    color: #33AA72;
}

.social-icons {
    display: flex;
    flex-direction: row;
    padding-top: 60px;
}

.social-icons a {
    margin-right: 20px;
    transition: transform 0.3s;
}

.social-icons a:hover {
    transform: scale(1.1, 1.1);
}

.big-logo {
    margin-left: auto;
}

/* Tablet view */

@media (max-width: 768px) {
    .main-content {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .main-text {
        text-align: center;
    }
    .main-text>h3 {
        font-size: 40px;
        line-height: 60px;
    }
    .big-logo {
        align-self: center;
        margin: 0px auto 60px auto;
    }
    .social-icons {
        display: flex;
        flex-direction: row;
        padding-top: 80px;
        align-self: center;
        justify-content: center;
    }
}

/* Mobile view */

@media (max-width: 576px) {
    .main-content {
        padding: 0;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    .main-text {
        padding: 0;
        margin: auto 20px;
        text-align: center;
    }
    .main-text>h3 {
        font-size: 25px;
        line-height: 30px;
    }
    .social-icons {
        padding-left: 20px;
        margin: 20px auto;
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: center;
    }
    .big-logo {
        align-self: center;
        margin: 30px auto 40px auto;
    }
}