@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
/*  */

.project-card {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 5px;
}

.card-img {
    padding: 0 0 5px 0;
    height: 70%;
    object-fit: fill;
}

.card-text {
    padding: 0px 10px 10px 10px;
    color: #212529;
    font-family: "Righteous";
    font-size: 20px;
    text-align: left;
}

.card-link {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #32aa72;
    font-family: "Red Hat Display";
    font-size: 15px;
    text-decoration: underline;
}

.project-iframe {
    width: 100%;
    height: 70%;
}

.project-link {
    position: absolute;
    padding: 10px;
    right: 10px;
    bottom: 10px;
    color: #32aa72;
    font-family: "Red Hat Display";
    font-size: 15px;
    text-decoration: underline;
}

.modal-title {
    font-family: "Red Hat Display";
    color: #212529;
}