@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  background-color: #121723;
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #212529;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #33aa72;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #212529;
  /* creates padding around scroll thumb */
}
